import React from "react";
import { Link, graphql } from "gatsby";

import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Bio from "../components/Bio";

import styles from "../styles/blog.module.scss";

export default function Blog({ data }) {
    return (
        <Layout>
            <Seo
                title="Blog"
                keywords={[`prikshit`, `tekta`, `portfolio`, `blog`]}
            />
            <Bio />

            <div className={styles.content}>
                {/* <h1 className={styles.blog}>Blog</h1> */}

                {data.allMdx.edges
                    .filter(({ node }) => {
                        const rawDate = node.frontmatter.rawDate;
                        const date = new Date(rawDate);
                        return date < new Date();
                    })
                    .map(({ node }) => (
                        <div key={node.id} className={styles.blogExcrpt}>
                            <Link
                                to={node.frontmatter.path}
                                className={`${styles.title} ${styles.link}`}
                            >
                                <h3 className={styles.text}>
                                    {node.frontmatter.title}
                                </h3>
                            </Link>
                            <div>
                                <h5 className={styles.desc}>
                                    {node.frontmatter.date}
                                </h5>
                                <h5 className={styles.desc}>
                                    {" "}
                                    - {node.fields.readingTime.text}
                                </h5>
                            </div>
                            {/* <p className={styles.blogPostExcrpt}>
                                {node.excerpt}
                            </p> */}
                        </div>
                    ))}
            </div>
        </Layout>
    );
}

export const query = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
        allMdx(
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { frontmatter: { draft: { eq: false } } }
        ) {
            totalCount
            edges {
                node {
                    id
                    frontmatter {
                        title
                        date(formatString: "DD MMMM, YYYY")
                        rawDate: date
                        path
                    }
                    fields {
                        readingTime {
                            text
                        }
                    }
                    excerpt
                }
            }
        }
    }
`;
